<template>
  <header class="bg-white border-b border-grey-100 relative">
    <div class="border-b border-grey-100">
      <div class="px-20 flex items-center justify-between h-60">
        <img :src="clientLogo" :alt="`${$settings.NAME} Logo`" class="h-30" />
        <button
          class="text-xl p-2"
          :aria-label="$t('common.exit')"
          @click="handleBack"
        >
          <CloseIcon :title="$t('common.exit')" />
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import CloseIcon from '@components/common/CloseIcon'

export default {
  components: { CloseIcon },
  computed: {
    clientLogo() {
      return `${this.$settings.S3_ROOT}/logo_primary.png`
    },
  },
  methods: {
    handleBack() {
      if (this.$listeners.onCancel) {
        this.$emit('onCancel')
      } else {
        this.$router.back()
      }
    }
  }
}
</script>
