<template>
  <FormMessage :text="text" color="bg-green-500" />
</template>
<script>
import FormMessage from './FormMessage.vue'
export default {
  components: { FormMessage },
  props: {
    text: {
      type: String,
      default: 'common.success',
    },
  },
}
</script>
