<template>
  <div class="flex flex-col items-center p-40 w-435">
    <h1 class="font-semibold h5">{{ $t(strings.exitTitle) }}</h1>
    <p class="mt-20 text-center">
      {{ $t(strings.exitDescription) }}
    </p>
    <HMButton class="mt-20" rounded="full" @click="exit">
      {{ $t(strings.exitConfirm) }}
    </HMButton>
    <HMButton class="mt-20" color="white" rounded="full" @click="cancel">
      {{ $t(strings.exitCancel) }}
    </HMButton>
  </div>
</template>

<script>
export default {
  props: {
    strings: {
      type: Object,
      default: () => {
        return {
          exitTitle: '',
          exitDescription: '',
          exitConfirm: '',
          exitCancel: '',
        }
      },
    },
  },
  methods: {
    exit() {
      this.$router.back()
    },
    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>
