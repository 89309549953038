<template>
  <div
    class="fixed bottom-0 right-0 w-screen bg-white py-25 px-40 flex justify-between items-center border-t"
  >
    <Modal :active="triggerModal" @close="triggerModal = false">
      <FormExitModal
        :strings="exitModalStrings"
        @cancel="triggerModal = false"
      />
    </Modal>
    <div class="w-200 h-40 flex items-center">
      <HMButton
        class="w-full"
        size="x-small"
        rounded="full"
        color="white"
        outlined
        @click="() => exit()"
      >
        {{ $t(backText) }}
      </HMButton>
    </div>
    <div
      style="max-width: 500px;"
      class="w-500 flex flex-col justify-center w-full mx-16"
    ></div>
    <div class="w-200 flex items-center">
      <HMButton
        class="w-full"
        size="x-small"
        rounded="full"
        :disabled="completeDisabled"
        @click="() => $emit('onComplete')"
      >
        {{ $t(completeText) }}
      </HMButton>
    </div>
  </div>
</template>

<script>
import Modal from '../Modal.vue'
import FormExitModal from './FormExitModal'

export default {
  components: { Modal, FormExitModal },
  props: {
    backText: { type: String, default: 'common.back_btn' },
    completeText: { type: String, default: 'common.update' },
    completeDisabled: { type: Boolean, default: false },
    showExitModal: { type: Boolean, default: false },
    exitModalStrings: {
      type: Object,
      default: () => {
        return {
          exitTitle: '',
          exitDescription: '',
          exitConfirm: '',
          exitCancel: '',
        }
      },
    },
  },
  data() {
    return {
      triggerModal: false,
    }
  },
  methods: {
    exit() {
      if (this.showExitModal) {
        this.triggerModal = true
      } else if (this.$listeners.onCancel) {
        this.$emit('onCancel')
      } else {
        this.$router.back()
      }
    },
  },
}
</script>
